import { useFeatureFlag } from '../../Providers/LaunchDarkly';
import { useRemoveOrderHoldOrCancelLiveDdpReview } from '../../useRemoveOrderHold.graphql';
import { isGuidedPreset, useGetSelectedWaxup, useSubmitGuidedWaxupReview } from './GuidedWaxup.util';
import { DesignReviewOption } from './components';
import { GuidedWaxupNavArea } from './components/GuidedWaxupNavArea';
import { GuidedWaxupAccessoryModals } from './components/LiveDesignReview/GuidedWaxupAccessoryModals.graphql';
import {
    useGetCalendlyPrefillData,
    useIsOrderEligibleForLiveDesignReview,
    useIsOrderEligibleForVideoDesignReview,
    useRejectionNotes,
} from './components/LiveDesignReview/GuidedWaxupScheduleLiveCall.hooks.graphql';
import { useIsConfirmationModalEnabled } from './hooks/liveMeetingSchedulers.graphql';
import { useGuidedWaxupAccessoryModal } from './hooks/useGuidedWaxupAccessoryModal';
import { useGuidedWaxupPresets } from './hooks/useGuidedWaxupPresets';
import { useShowLdrSurvey } from './hooks/useShowLdrSurvey';
import { CurrentWaxupDesktopScreen } from './screens/CurrentWaxupDesktopScreen';
import { CurrentWaxupMobileScreen } from './screens/CurrentWaxupMobileScreen';
import { HistoricalWaxupDesktopScreen } from './screens/HistoricalWaxupDesktopScreen';
import { HistoricalWaxupMobileScreen } from './screens/HistoricalWaxupMobileScreen';
import { ldrOptionsModalSelectionAtom } from './state/GuidedWaxup.atoms';
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import { useGuidedWaxupSelector, useWaxupIsRejected } from './state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import { getFragmentData, VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
    LabsGqlLiveDdpReviewRequestEntryPoint,
} from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const HistoricalWaxupsScreens: React.VFC = () => {
    const { selectedTab } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const guidedPreset = isGuidedPreset(selectedTab);

    const selectedWaxup = useGetSelectedWaxup();
    const isApprovedWaxup = selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;

    // Always show the nav area if the selected revision was a rejection, but if vieweing an approved waxup,
    // we hide the nav area when viewing the general view preset since there's no rejection note to view
    const showNavArea = !isApprovedWaxup || guidedPreset;

    return isMobile ? (
        <>
            <HistoricalWaxupMobileScreen
                selectedDesignRevisionNotes={selectedWaxup?.notes ?? undefined}
                isApprovedWaxup={isApprovedWaxup}
            />
            {showNavArea && <GuidedWaxupNavArea />}
        </>
    ) : (
        <HistoricalWaxupDesktopScreen
            selectedDesignRevisionNotes={selectedWaxup?.notes ?? undefined}
            isApprovedWaxup={isApprovedWaxup}
        />
    );
};

const CurrentWaxupScreens: React.VFC = () => {
    const [, setShowLdrSurvey] = useShowLdrSurvey();
    const navigate = useNavigate();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const {
        refetch,
        order,
        selectedDesignRevisionId,
        designRevisionFragments,
        guidedVideoWalkthroughUrl,
        vdrRequestedByDoctor,
    } = useGuidedWaxupContext();
    const waxupRejected = useWaxupIsRejected();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const generalRejectionNote =
        useGuidedWaxupSelector(s => s.presets[LabsGqlGuidedWaxupPresetType.GeneralView]?.notes) ?? '';
    const calendlyPrefill = useGetCalendlyPrefillData();
    const rejectionNotes = useRejectionNotes();

    const [modal, setModal] = useGuidedWaxupAccessoryModal();
    const [selectedDesignReviewOption] = useAtom(ldrOptionsModalSelectionAtom);

    const designRevisions = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        designRevisionFragments,
    );

    const rejections = _.sumBy(designRevisions, revision =>
        revision.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected ? 1 : 0,
    );

    const { value: enableLdrOptionsModal } = useFeatureFlag('enableLdrOptionsModal');
    const isLdrEnabled = useIsOrderEligibleForLiveDesignReview(order);
    const isVdrEnabled = useIsOrderEligibleForVideoDesignReview(order);
    const isConfirmationModalEnabled = useIsConfirmationModalEnabled();

    React.useEffect(() => {
        if (isVdrEnabled && selectedDesignRevisionId) {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Video Design Review - Available', {
                $groups: {
                    order: order.id,
                },
                revisionId: selectedDesignRevisionId,
                numRejections: rejections,
                requestedByDoctor: !!vdrRequestedByDoctor,
            });
        }
    }, [isVdrEnabled, selectedDesignRevisionId, rejections, order.id, vdrRequestedByDoctor]);

    const { submit } = useSubmitGuidedWaxupReview(async () => {
        if (!isConfirmationModalEnabled || selectedDesignReviewOption === DesignReviewOption.CONTINUE_WITHOUT_REVIEW) {
            await refetch();
            navigate(`/${PracticeScreen.orders}/${order.id}`, { replace: true });
        }
    });

    const { onSubmit: onSubmitRemoveHold, isLiveDdp } = useRemoveOrderHoldOrCancelLiveDdpReview(
        async () => {
            await refetch();
        },
        {
            orderId: order.id,
            holdId: order.hold_details?.hold_id,
        },
    );

    const submitData = async ({ showLdrSurvey }: { showLdrSurvey: boolean }) => {
        if (order.hold_details?.hold_is_practice_managed_pause || isLiveDdp) {
            await onSubmitRemoveHold();
        }

        if (isLdrEnabled && showLdrSurvey && waxupRejected) {
            setShowLdrSurvey(true);
        }

        // If there is a video walkthrough URL, track the survey response.
        if (guidedVideoWalkthroughUrl) {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Guided Waxup - Video Design Review - Review Submitted',
                {
                    $groups: {
                        order: order.id,
                    },
                    revisionId: selectedDesignRevisionId,
                    review: presets.VIDEO_WALKTHROUGH?.notes,
                    requestedByDoctor: !!vdrRequestedByDoctor,
                },
            );
        }

        await submit({
            order_id: order.id,
            rejection: waxupRejected
                ? {
                      notes: generalRejectionNote,
                      presets: Object.entries(presets).map(([type, info]) => ({
                          preset_type: type as LabsGqlGuidedWaxupPresetType,
                          preset_status:
                              type === LabsGqlGuidedWaxupPresetType.GeneralView
                                  ? info.status ?? LabsGqlGuidedWaxupPresetStatus.Rejected
                                  : info.status ?? LabsGqlGuidedWaxupPresetStatus.Skipped,
                          annotated_image_urls: info.annotatedImageUrls ?? [],
                          structured_notes: info.structured_notes?.map(category => ({ category })) ?? [],
                          notes: info.notes ?? '',
                      })),
                  }
                : null,
        });
    };

    const onSubmit = async () => {
        const orderIsPaused = order.hold_details?.hold_is_practice_managed_pause;

        if (isLdrEnabled && waxupRejected && enableLdrOptionsModal) {
            return setModal('options');
        }

        if (isLdrEnabled && isLiveDdp && orderIsPaused) {
            return setModal('cancel');
        }

        await submitData({ showLdrSurvey: true });
    };

    return (
        <>
            {isMobile ? (
                <>
                    <CurrentWaxupMobileScreen />
                    <GuidedWaxupNavArea submit={onSubmit} />
                </>
            ) : (
                <CurrentWaxupDesktopScreen submit={onSubmit} />
            )}
            {isLdrEnabled && selectedDesignRevisionId && (
                <GuidedWaxupAccessoryModals
                    entryPoint={LabsGqlLiveDdpReviewRequestEntryPoint.PracticeGuidedWaxupReviewLdrOptionsModal}
                    orderId={order.id}
                    revisionId={selectedDesignRevisionId}
                    modal={modal}
                    setModal={setModal}
                    isMobile={isMobile}
                    onDesignReviewedAction={submitData}
                    isRejected={waxupRejected}
                    calendlyPrefill={calendlyPrefill}
                    numRejections={rejections}
                    isRefab={!!order.original_order_id}
                    ddpReadinessDate={order.practice_dates.digital_design_preview_estimated_completion_date}
                    rejectionNotes={rejectionNotes}
                    onScheduleCompletion={() => {
                        navigate(`/${PracticeScreen.orders}/${order.id}`, { replace: true });
                        window.location.reload();
                    }}
                />
            )}
        </>
    );
};

export const GuidedWaxupScreenWrapper: React.VFC = () => {
    const { design, order, selectedDesignRevisionAlreadyReviewed, selectedDesignFragment } = useGuidedWaxupContext();
    useGuidedWaxupPresets(order, design);

    if (!selectedDesignFragment) {
        return null;
    }

    if (selectedDesignRevisionAlreadyReviewed) {
        return <HistoricalWaxupsScreens />;
    }

    return <CurrentWaxupScreens />;
};

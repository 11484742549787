import { useCurrentWaxup } from '../Waxup.util.graphql';
import { GuidedWaxupWrapper } from './GuidedWaxupWrapper';
import { useTabSelection } from './hooks/useTabSelection';
import { useVideoWalkthroughUrl } from './hooks/useVideoWalkthroughContent.graphql';
import { ScrollToProvider } from './state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeFullScreenDialog, PracticeScreen } from '@orthly/dentin';
import { VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useOrder } from '@orthly/graphql-react';
import { LabsGqlDesignOrderDoctorReviewStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { CartItemV2Utils, DentureType, ItemCheckerUtils, LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { ErrorPage, LoadBlocker, useEnableSentrySessionReplay } from '@orthly/ui';
import { SentimentVeryDissatisfiedIcon, styled } from '@orthly/ui-primitives';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

const LoadBlockerContent = styled('div')({
    height: '100dvh',
});

type Props = {
    OrderChatWrapper?: React.FC<{
        order: LabsGqlSingleLabOrderFragment;
        hideFooter?: boolean;
    }>;
    internalEvaluation?: boolean;
    displayInline?: boolean;
    onlyShowModelViewer?: boolean;
};

const GuidedWaxupContent: React.VFC<Props> = ({
    OrderChatWrapper,
    internalEvaluation,
    displayInline,
    onlyShowModelViewer,
}) => {
    useEnableSentrySessionReplay();

    // revisionId is used only when a previous design is selected from the order details page, storing the index of the revision in the url.
    const { orderId, revisionId } = useParams<{ orderId: string; revisionId?: string }>();
    const { order, loading: loadingOrder, refetch } = useOrder(orderId, { fetchPolicy: 'no-cache' });
    const navigate = useNavigate();
    const items = React.useMemo(() => OrderItemV2Utils.parseItems(order?.items_v2 ?? []), [order?.items_v2]);
    const teeth = React.useMemo(() => items.flatMap(item => CartItemV2Utils.getUniqueUNNs(item)), [items]);
    const [isWarningDialogModalOpen, setIsWarningDialogModalOpen] = React.useState<boolean>(false);
    const [isSkipToGeneralViewModalOpen, setIsSkipToGeneralViewModalOpen] = React.useState<boolean>(false);
    const [isIncompletedWorkReminderModalOpen, setIsIncompletedWorkReminderModalOpen] = React.useState<boolean>(false);
    const [isMobileRejectionNoteOpen, setIsMobileRejectionNoteOpen] = React.useState<boolean>(false);
    const [isMobileTimelineDialogOpen, setIsMobileTimelineDialogOpen] = React.useState<boolean>(false);
    const [isMobileDoctorImagesDialogOpen, setIsMobileDoctorImagesDialogOpen] = React.useState<boolean>(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = React.useState<boolean>(false);
    const [isAnnotatingScreenshot, setIsAnnotatingScreenshot] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useTabSelection({ orderId, revisionId });

    const waxupState = useCurrentWaxup(order, revisionId, internalEvaluation);

    const { loading: videoLoading, url: videoUrl, vdrRequestedByDoctor } = useVideoWalkthroughUrl(order?.id);

    if (!order || !waxupState.slimDesignFragments || videoLoading) {
        if (!loadingOrder && !waxupState.loading && !videoLoading) {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Guided Waxup - Failed to Retrieve and Load Order',
                {
                    $groups: {
                        order: orderId,
                    },
                    revisionId: revisionId,
                },
            );
            Sentry.captureException(
                new Error('Failed to retrieve and load order when entering the guided waxup flow'),
                { extra: { orderId } },
            );
            return (
                <ErrorPage
                    message={'Error'}
                    text={'Something went wrong with the order, please try again later.'}
                    icon={SentimentVeryDissatisfiedIcon}
                />
            );
        } else {
            return (
                <LoadBlocker blocking={true}>
                    <LoadBlockerContent />
                </LoadBlocker>
            );
        }
    }

    const slimDesigns = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        waxupState.slimDesignFragments,
    );

    const orderHasAtLeastOnePreviousWaxup = slimDesigns.some(design => !!design.design_source);
    const shouldUseGuidedWaxupComparison = orderHasAtLeastOnePreviousWaxup;

    const currentDesign = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        waxupState.currentDesignFragment,
    );

    const comparisonDesign = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        waxupState.comparisonDesignFragment,
    );

    const viewingPreviousDesign =
        !currentDesign?.is_latest_design &&
        currentDesign?.doctor_review?.status !== LabsGqlDesignOrderDoctorReviewStatus.Approved;

    const { patient } = order;
    const patientName = patient ? `${patient.first_name} ${patient.last_name}` : '';

    const isImmediateDenture = items.some(
        el => OrderItemV2Utils.itemIsType(el, LabOrderItemSKUType.Denture) && el.denture_type === DentureType.Immediate,
    );
    const isRemoveable = items.some(el => ItemCheckerUtils.isPartialDenture(el) || ItemCheckerUtils.isDentureItem(el));
    const waxUpWrapper = (
        <GuidedWaxupWrapper
            order={order}
            refetch={refetch}
            viewingPreviousDesign={viewingPreviousDesign}
            selectedTab={selectedTab ?? LabsGqlGuidedWaxupPresetType.GeneralView}
            setSelectedTab={setSelectedTab}
            onBack={() => {
                if (revisionId || shouldUseGuidedWaxupComparison) {
                    BrowserAnalyticsClientFactory.Instance?.track('Practice - Guided Waxup - Exit', {
                        $groups: {
                            order: orderId,
                        },
                        revisionId,
                    });
                    navigate(`/${PracticeScreen.orders}/${order.id}`, { replace: true });
                } else if (viewingPreviousDesign) {
                    waxupState.setSelectedRevisionId(
                        slimDesigns.find(
                            design =>
                                design.is_latest_design ||
                                design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved,
                        )?.id,
                    );
                } else {
                    setIsWarningDialogModalOpen(true);
                }
            }}
            userRole={'practitioner'}
            selectedDesignFragment={waxupState.currentDesignFragment}
            mostRecentlyRejectedDesignFragment={waxupState.mostRecentlyRejectedDesignFragment}
            comparisonDesignFragment={waxupState.comparisonDesignFragment}
            setComparisonDesignRevisionId={waxupState.setComparisonRevisionId}
            designRevisionFragments={waxupState.slimDesignFragments}
            selectedDesignRevisionId={currentDesign?.id}
            setSelectedDesignRevisionId={waxupState.setSelectedRevisionId}
            comparisonDesignRevisionId={comparisonDesign?.id}
            selectedDesignRevisionAlreadyReviewed={!!currentDesign?.doctor_review}
            patientName={patientName}
            isWarningDialogModalOpen={isWarningDialogModalOpen}
            setIsWarningDialogModalOpen={setIsWarningDialogModalOpen}
            isSkipToGeneralViewModalOpen={isSkipToGeneralViewModalOpen}
            setIsSkipToGeneralViewModalOpen={setIsSkipToGeneralViewModalOpen}
            isIncompletedWorkReminderModalOpen={isIncompletedWorkReminderModalOpen}
            setIsIncompletedWorkReminderModalOpen={setIsIncompletedWorkReminderModalOpen}
            isMobileRejectionNoteOpen={isMobileRejectionNoteOpen}
            setIsMobileRejectionNoteOpen={setIsMobileRejectionNoteOpen}
            isMobileTimelineDialogOpen={isMobileTimelineDialogOpen}
            setIsMobileTimelineDialogOpen={setIsMobileTimelineDialogOpen}
            isMobileDoctorImagesDialogOpen={isMobileDoctorImagesDialogOpen}
            setIsMobileDoctorImagesDialogOpen={setIsMobileDoctorImagesDialogOpen}
            isAnnotatingScreenshot={isAnnotatingScreenshot}
            setIsAnnotatingScreenshot={setIsAnnotatingScreenshot}
            teeth={teeth}
            revisionId={revisionId ? Number(revisionId) : undefined}
            enableGuidedWaxupComparison={shouldUseGuidedWaxupComparison}
            isSidebarExpanded={isSidebarExpanded}
            setIsSidebarExpanded={setIsSidebarExpanded}
            OrderChatWrapper={OrderChatWrapper}
            internalEvaluation={internalEvaluation}
            isImmediateDenture={isImmediateDenture}
            isRemoveable={isRemoveable}
            onlyShowModelViewer={onlyShowModelViewer ?? false}
            guidedVideoWalkthroughUrl={videoUrl}
            vdrRequestedByDoctor={!!vdrRequestedByDoctor}
        />
    );
    if (displayInline) {
        return waxUpWrapper;
    }
    return <PracticeFullScreenDialog>{waxUpWrapper}</PracticeFullScreenDialog>;
};

export const GuidedWaxupRoot: React.VFC<Props> = props => (
    <ScrollToProvider>
        <GuidedWaxupContent {...props} />
    </ScrollToProvider>
);

import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { useSetGuidedWaxupAccessoryModal } from '../hooks/useGuidedWaxupAccessoryModal';
import { videoDesignReviewVideoPlayedAtom } from '../state/GuidedWaxup.atoms';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { FormControlLabel } from '@mui/material';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleSelect, useScreenIsMd } from '@orthly/ui';
import {
    Box,
    Text,
    styled,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Icon,
    FlossPalette,
} from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';
import ReactPlayer from 'react-player';

const Layout = styled(Box)(({ theme }) => ({
    display: 'grid',
    width: '100%',
    padding: '24px',
    gap: '16px',
    maxWidth: '1200px',
    [theme.breakpoints.down('lg')]: {
        padding: 0,
        maxWidth: 'unset',
        gap: '8px',
    },
}));

const PlayerWrapper = styled(Box)({
    aspectRatio: '16 / 9',
    maxHeight: 'calc(100vh - 300px)',
    position: 'relative',
});

const PlayerOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    cursor: 'pointer',
    '&::before, &::after': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: FlossPalette.WHITE,
    },
    '&::before': {
        boxShadow: '0px 5px 12.5px 0px #0000000D',
        opacity: '0.3',
        height: '120px',
        width: '120px',
    },
    '&::after': {
        height: '106px',
        width: '106px',
    },
    '&:hover&::after, &:hover': {
        opacity: '0.9',
    },
});

const PlayIcon = styled(Icon)({
    height: '70px',
    width: '70px',
    color: FlossPalette.BLACK,
    zIndex: 1,
});

const StyledReactPlayer = styled(ReactPlayer)(({ theme }) => ({
    video: {
        borderRadius: '10px',
        [theme.breakpoints.down('lg')]: {
            borderRadius: 0,
        },
    },
}));

const SurveyLayout = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('lg')]: {
        padding: '8px 16px 16px 16px',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto',
        gap: '24px',
    },
}));

const SurveySection = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: '16px',
    height: 'fit-content',
    [theme.breakpoints.down('lg')]: {
        gap: '8px',
    },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
        flexWrap: 'nowrap',
    },
}));

const StyledRadioButton = styled(FormControlLabel)({
    borderRadius: '16px',
    paddingRight: '20px',
    marginLeft: 0,
    ...GUIDED_WAXUP_COMMON_STYLES,
});

const StyledHeader = styled(Text)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        margin: '16px 16px 4px 16px',
    },
}));

const ActionText = styled(Text)({
    cursor: 'pointer',
});

enum RadioOption {
    Yes = 'Yes',
    No = 'No',
}

enum AbandonmentReason {
    NotExplainedClearly = 'The changes weren’t explained clearly',
    TooLong = 'The video was too long',
    TooShort = 'The video was too short or lacked detail',
    DidNotAddressConcerns = 'The video didn’t address my concerns',
    PreferWritten = 'I prefer written explanation over video',
    Other = 'Other',
}

const useHasVideoBeenPlayed = () => {
    const { selectedDesignRevisionId, order } = useGuidedWaxupContext();

    const [videoHasPlayed, setVideoHasPlayed] = useAtom(videoDesignReviewVideoPlayedAtom);
    const key = `${order.id}-${selectedDesignRevisionId}`;

    const hasPlayed = videoHasPlayed[key] ?? false;

    const markHasPlayed = () => {
        setVideoHasPlayed(prev => ({
            ...prev,
            [key]: true,
        }));
    };

    return { hasPlayed, markHasPlayed };
};

const useVideoControls = () => {
    const { selectedDesignRevisionId, order, vdrRequestedByDoctor } = useGuidedWaxupContext();
    const { hasPlayed, markHasPlayed } = useHasVideoBeenPlayed();

    const [duration, setDuration] = React.useState<number>(0);

    const args = React.useMemo(() => {
        return {
            $groups: {
                order: order.id,
            },
            revisionId: selectedDesignRevisionId,
            totalVideoDuration: duration,
            requestedByDoctor: !!vdrRequestedByDoctor,
        };
    }, [order.id, selectedDesignRevisionId, duration, vdrRequestedByDoctor]);

    const onPlay = () => {
        if (!hasPlayed) {
            markHasPlayed();
        }
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Played',
            args,
        );
    };

    const onPause = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Paused',
            args,
        );
    };

    const onEnded = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Finished',
            args,
        );
    };

    const onDuration = (duration: number) => {
        setDuration(duration);
    };

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Displayed',
            args,
        );
    }, [args]);

    return {
        onPlay,
        onPause,
        onEnded,
        onDuration,
    };
};

const useSurveyData = () => {
    const setNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const videoWalkthroughPreset = useGuidedWaxupSelector(
        state => state.presets[LabsGqlGuidedWaxupPresetType.VideoWalkthrough],
    );
    const [selectedOption, setSelectedOption] = React.useState<RadioOption | null>(() => {
        if (videoWalkthroughPreset?.notes === 'Yes') {
            return RadioOption.Yes;
        } else if (videoWalkthroughPreset?.notes) {
            return RadioOption.No;
        }
        return null;
    });
    const [reason, setReason] = React.useState<string>(() => {
        if (videoWalkthroughPreset?.notes && videoWalkthroughPreset.notes !== 'Yes') {
            return videoWalkthroughPreset.notes;
        }
        return '';
    });

    React.useEffect(() => {
        if (videoWalkthroughPreset?.notes === 'Yes') {
            setSelectedOption(RadioOption.Yes);
            setReason('');
        } else if (videoWalkthroughPreset?.notes) {
            setSelectedOption(RadioOption.No);
            setReason(videoWalkthroughPreset.notes);
        }
    }, [videoWalkthroughPreset]);

    const onVideoIsHelpfulChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedOption = e.target.value as RadioOption;

        setSelectedOption(updatedOption);
        setReason('');

        if (updatedOption === RadioOption.Yes) {
            setNotes({ presetName: LabsGqlGuidedWaxupPresetType.VideoWalkthrough, rejectionNotes: 'Yes' });
        } else if (updatedOption === RadioOption.No) {
            setNotes({ presetName: LabsGqlGuidedWaxupPresetType.VideoWalkthrough, rejectionNotes: '' });
        }
    };

    const onVideoNotHelpfulReasonChange = (value: string) => {
        setReason(value);
        setNotes({ presetName: LabsGqlGuidedWaxupPresetType.VideoWalkthrough, rejectionNotes: value });
    };

    return {
        selectedOption,
        reason,
        onVideoIsHelpfulChange,
        onVideoNotHelpfulReasonChange,
    };
};

const VideoPlayer: React.FC<{ url: string }> = ({ url }) => {
    const videoControls = useVideoControls();
    const internalPlayerRef = React.useRef<HTMLVideoElement>();
    const [overlayShown, setOverlayShown] = React.useState(true);
    const { value: enableVdrPlayOverlay = false } = useFeatureFlag('enableVdrPlayOverlay');
    const showOverlay = enableVdrPlayOverlay && overlayShown && internalPlayerRef.current;

    return (
        <PlayerWrapper>
            <StyledReactPlayer
                {...videoControls}
                controls={!showOverlay}
                url={url}
                width={'100%'}
                height={'100%'}
                ref={ref => {
                    internalPlayerRef.current = ref?.getInternalPlayer() as HTMLVideoElement;
                }}
            />
            {showOverlay && (
                <PlayerOverlay
                    onClick={async () => {
                        if (internalPlayerRef.current) {
                            await internalPlayerRef.current.play();
                            setOverlayShown(false);
                        }
                    }}
                >
                    <PlayIcon icon={'PlayArrowIcon'} />
                </PlayerOverlay>
            )}
        </PlayerWrapper>
    );
};

export const GuidedWaxupVideoWalkthroughInner: React.VFC<{ url: string }> = ({ url }) => {
    const { selectedOption, reason, onVideoIsHelpfulChange, onVideoNotHelpfulReasonChange } = useSurveyData();
    const { setSelectedTab, selectedDesignRevisionId, selectedDesignRevisionAlreadyReviewed, vdrRequestedByDoctor } =
        useGuidedWaxupContext();

    const screenIsMd = useScreenIsMd();

    const onSkipToReviewAction = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Add Notes Clicked',
            {
                $groups: {
                    order: 'orderId',
                },
                revisionId: selectedDesignRevisionId,
                requestedByDoctor: !!vdrRequestedByDoctor,
            },
        );

        setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
    };

    const { hasPlayed, markHasPlayed } = useHasVideoBeenPlayed();
    const setModal = useSetGuidedWaxupAccessoryModal();

    const hasMounted = React.useRef(false);
    const hasPlayedRef = React.useRef(hasPlayed); // To track the previous state of hasPlayed

    // Ensure hasMounted is set to true after initial render
    React.useEffect(() => {
        hasMounted.current = true;
        return () => {
            if (hasMounted.current && !hasPlayedRef.current && url) {
                setModal('vdr-abandonment'); // Show modal only if video hasn't been played and URL exists
                markHasPlayed();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Track the previous state of hasPlayed
    React.useEffect(() => {
        hasPlayedRef.current = hasPlayed;
    }, [hasPlayed]);

    return (
        <Layout>
            {!selectedDesignRevisionAlreadyReviewed && (
                <StyledHeader variant={screenIsMd ? 'body1' : 'h6'} medium>
                    Watch a Lab Technician walkthrough of your most recent design changes
                </StyledHeader>
            )}

            <VideoPlayer url={url} />

            {!selectedDesignRevisionAlreadyReviewed && (
                <SurveyLayout>
                    <SurveySection>
                        <Text variant={screenIsMd ? 'body2' : 'body1'} medium>
                            Was this video helpful?
                        </Text>

                        <StyledRadioGroup
                            row
                            defaultValue={''}
                            value={selectedOption}
                            onChange={onVideoIsHelpfulChange}
                        >
                            <StyledRadioButton
                                value={RadioOption.Yes}
                                control={<Radio color={'secondary'} />}
                                label={
                                    <Text variant={'body2'} medium>
                                        Yes
                                    </Text>
                                }
                            />
                            <StyledRadioButton
                                value={RadioOption.No}
                                control={<Radio color={'secondary'} />}
                                label={
                                    <Text variant={'body2'} medium>
                                        No
                                    </Text>
                                }
                            />
                        </StyledRadioGroup>
                    </SurveySection>

                    {selectedOption === RadioOption.No && (
                        <SurveySection>
                            <Text variant={screenIsMd ? 'body2' : 'body1'} medium>
                                Why wasn’t the video helpful?
                            </Text>

                            <Box>
                                <SimpleSelect
                                    value={reason}
                                    label={'Make a selection'}
                                    options={Object.values(AbandonmentReason).map(value => ({ label: value, value }))}
                                    onChange={value => onVideoNotHelpfulReasonChange(value ?? '')}
                                />
                                {reason && (
                                    <Text variant={'body2'} color={'GRAY'}>
                                        Want to reject the design? Add notes{' '}
                                        <ActionText
                                            component={'span'}
                                            variant={'body2'}
                                            medium
                                            color={'PRIMARY_FOREGROUND'}
                                            onClick={onSkipToReviewAction}
                                        >
                                            here
                                        </ActionText>
                                    </Text>
                                )}
                            </Box>
                        </SurveySection>
                    )}
                </SurveyLayout>
            )}
        </Layout>
    );
};

export const GuidedWaxupVideoWalkthrough: React.VFC = () => {
    const { guidedVideoWalkthroughUrl } = useGuidedWaxupContext();

    return guidedVideoWalkthroughUrl ? <GuidedWaxupVideoWalkthroughInner url={guidedVideoWalkthroughUrl} /> : null;
};
